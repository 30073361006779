<template>
    <div class="device-m">
        
        <slot></slot>

    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss">
@import '@/style/page/xy/m-index';
@import '@/style/components/m-index';

</style>