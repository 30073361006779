<template>
  <div class='device-pc'>

    <slot></slot>

  </div>
</template>

<script>

export default {}
</script>

<style lang='scss'>
@import '@/style/page/xy/pc-index';
@import '@/style/components/pc-index';

.device-pc {
  background-size: 100% auto;
}

</style>